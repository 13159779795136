/* You can add global styles to this file, and also import other style files */
.card > .card-body > .card-title > .btn-toolbar.justify-content-between h5.d-inline {
  margin-left: 7px;
  position: relative;
  top: 1.5px;
}

.grid-container {
  overflow-y: hidden;
  overflow-x: auto;
}
